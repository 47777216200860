























































import { Component, Vue } from 'vue-property-decorator';
import * as lib from '@/scripts/apiHelper.ts';
import * as rules from '@/scripts/rules.ts';
import { ApiProfile } from '@/scripts/apiProfile.ts';

@Component({
  components: {}
})
export default class Login extends Vue {
  private show = true;
  private errors = '';
  private loading = false;
  private showPassword = false;

  private userName = '';
  private password = '';

  public showLogin(): void {
    this.show = true;
  }

  private hideLogin(): void {
    this.userName = '';
    this.password = '';
    this.errors = '';
    this.show = false;
    window.location.href = '#/';
  }

  private enterEvent(): void {
    this.submit();
  }

  private forgotPassword(): void {
    this.userName = '';
    this.password = '';
    this.errors = '';
    this.show = false;
    // Redirect to front page
    window.location.href = '#/RecoverPassword';
  }

  get emailRules(): ((v: string) => boolean | string)[] {
    return rules.emailRules();
  }

  get passwordRules(): ((v: string) => boolean | string)[] {
    return rules.passwordRules();
  }

  // Auth and store token in local storage
  private async submit(): Promise<void> {
    this.errors = '';
    this.loading = true;

    const pwd = this.password;
    this.password = ''; // clear password in form. Do not let it stay too long...
    try {
      // Authenticate via API
      const authResponse = await ApiProfile.authenticate({
        UserName: this.userName.trim(),
        Password: btoa(pwd)
      });
      // Save token and expiration to session storage
      window.sessionStorage.setItem('token', authResponse.token);
      window.sessionStorage.setItem('expiration', new Date(authResponse.expiration).toJSON());
      // Get profile id
      const proIdResponse = await ApiProfile.profileIdByUserName(this.userName);
      // Save profile id to session storage
      window.sessionStorage.setItem('profileid', btoa(proIdResponse.ProfileId));
      window.sessionStorage.setItem('searchid', btoa(proIdResponse.SearchId));

      // Redirect to Dashboard page
      // eslint-disable-next-line
      window.location.href = '#/Dashboard';
    } catch (error) {
      this.errors = lib.getErrorMessage(error);
    } finally {
      this.loading = false;
    }
  }
}
